import { Box, Button, Container, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { FunctionComponent } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RouteApp } from "../../common/model/RouteApp";
import imageback from '../../images/img-fond-fonce.svg';
import TechnicalSheetComponent from "./TechnicalSheetComponent";
import { DataSheet, OrderDetail } from "../model/OrderDetail";
import { useSelector } from "react-redux";
import { RootState } from "../../common/redux/store";
import { useAppDispatch } from "../../common/redux/reduxHooks";
import { getOrderById } from "../redux/ordersActions";
import './DatasheetsPage.scss';

const isResoFullHD: boolean = window.innerWidth > 1280;

interface DatasheetsPageProps {

}

const DatasheetsPage: FunctionComponent<DatasheetsPageProps> = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const orderSelector: OrderDetail | undefined = useSelector((rootState: RootState) => rootState.orders.data.orderDetail);

  const { idOrder } = useParams();

  useEffect(() => {
    if (idOrder && Number.parseInt(idOrder) !== orderSelector?.id) {
      dispatch(getOrderById(Number.parseInt(idOrder)));
    }
  }, [idOrder]);

  return (
    <Box className="page-container"
      sx={{
        backgroundImage: `url(${imageback})`
      }}
    >
      <Container sx={{ minWidth: isResoFullHD ? '1600px' : '', paddingBottom: '0px' }} className="container">
        <Box sx={{
          paddingX: isResoFullHD ? 12 : 1,
          paddingY: isResoFullHD ? 5 : 1
        }}>
          <Box sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: isResoFullHD ? 12 : 6
          }}>
            <Typography sx={{ fontSize: isResoFullHD ? '2.125rem' : '1.750rem'}} color={'white'} variant='h4' component={'h1'}>Fiches techniques - Commande {' ' + orderSelector?.reference}</Typography>
            <Button variant="outlined" color="error" onClick={() => navigate(
              RouteApp.ORDERS + RouteApp.ORDER_DETAIL + '/' + idOrder, { 
                state: { 
                  previousPage: location.state['previousPage'],
                  isListDisplay: location.state['isListDisplay'], 
                  isDisplayCurrentOrder: location.state['isDisplayCurrentOrder'],
                  searchValue: location.state['searchValue'],
                  status: location.state['status'],
                  filter: location.state['filter'],
                  startDate: location.state['startDate'],
                  endDate: location.state['endDate']
                }
              })}
              sx={{
                backgroundColor: "white",
                fontWeight: "bold",
                fontSize: isResoFullHD ? "1.2em" : '1rem',
                paddingX: 6,
                paddingY: 1,
                '&:hover': {
                  backgroundColor: "white",
                  fontWeight: "bold",
                  fontSize: isResoFullHD ? "1.2em" : '1rem',
                  paddingX: 6,
                  paddingY: 1,
                  opacity: 0.8
                }
              }}
            >
              Voir la commande
            </Button>
          </Box>

          {
            orderSelector?.data_sheets.length === 0 ?
              <Typography variant="h4" component="h2" color={'white'}>Aucune fiche technique n'est disponible pour le(s) produit(s) de cette commande</Typography>
              :
              <Box
                sx={{
                  maxHeight: isResoFullHD ? '750px' :'500px',
                  overflowY: 'scroll'
                }}
                className="datasheets-page-items-container"
              >
                {
                  orderSelector?.data_sheets.map((dataSheet: DataSheet, indice: number) => {
                    return <TechnicalSheetComponent currentTechnicalSheet={dataSheet} key={indice} />
                  })
                }
              </Box>
          }
        </Box>
      </Container>
    </Box>
  );
}

export default DatasheetsPage;