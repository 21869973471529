import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Container, FormControl, IconButton, InputBase, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { frFR } from '@mui/x-date-pickers/locales';
import { Dayjs } from 'dayjs';
import "dayjs/locale/fr";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CustomSnackbar from "../../common/component/CustomSnackbar";
import PaginationComponent from "../../common/component/PaginationComponent";
import { ResponseMessage } from "../../common/model/ResponseMessage";
import { RouteApp } from "../../common/model/RouteApp";
import { Role } from "../../common/model/User";
import { useAppDispatch } from "../../common/redux/reduxHooks";
import { RootState } from "../../common/redux/store";
import { ReactComponent as PlusIcon } from '../../images/Icon-plus-circle-noir.svg';
import imageback from '../../images/img-fond-fonce.svg';
import { EnumEstimateStatus } from "../model/EnumEstimatesStatus";
import { Estimate, EstimateStatus } from "../model/Estimate";
import { getAllEstimateStatus, getFilteredPaginedEstimates } from "../redux/estimatesActions";
import { estimatesSetResponseMessage } from "../redux/estimatesSlice";
import './EstimatesPage.scss';
import ItemDemandComponent from "./ItemDemandComponent";
import ItemEstimateComponent from "./ItemEstimateComponent";
import { formatDateYYYY_MM_DD } from '../../common/service/dateService';

interface EstimatesPageProps {

}

export enum TitlePage {
  DEMANDS = 'Mes demandes de prix',
  ESTIMATES = 'Mes devis reçus'
}


const EstimatesPage: FunctionComponent<EstimatesPageProps> = () => {

  const dayjs = require('dayjs');
  dayjs.locale('fr');

  const navigate = useNavigate();

  const location = useLocation();

  const dispatch = useAppDispatch();

  const roleUserConnected: Role | undefined = useSelector((state: RootState) => state.userConnected.user?.roles ? state.userConnected.user?.roles[0] : undefined);

  const estimatesSelector = useSelector((rootState: RootState) => rootState.estimates);
  const [snackbarMessage, setsnackbarMessage] = useState<ResponseMessage | undefined>(undefined);
  const [openSnackbar, setopenSnackbar] = useState<boolean>(false);

  const [rowsPerPage] = React.useState<number>(window.innerWidth > 1280 ? 8 : 4);

  const [currentPage, setcurrentPage] = useState<number>(location.state && location.state['currentPage'] ? location.state['currentPage'] : 1);

  const [titlePage, settitlePage] = useState(location.state && location.state['titlePage'] ? location.state['titlePage'] : TitlePage.DEMANDS);

  const [isEstimateView, setisEstimateView] = useState<boolean>(location.state && location.state['isEstimateView'] ? location.state['isEstimateView'] : false);

  const [filter, setfilter] = useState<"createdAt" | "estimateNumber" | "status" | 'empty'>(location.state && location.state['filter'] ? location.state['filter'] : 'empty');

  const [startDate, setstartDate] = useState<Dayjs | null>(location.state && location.state['startDate'] ? dayjs(location.state['startDate']) : null);

  const [endDate, setendDate] = useState<Dayjs | null>(location.state && location.state['endDate'] ? dayjs(location.state['endDate']) : null);

  const [searchValue, setsearchValue] = useState<string>(location.state && location.state['searchValue'] ? location.state['searchValue'] : '');

  const [status, setstatus] = useState<number | null>(location.state && location.state['status'] ? location.state['status'] : null);

  useEffect(() => {
    if (isEstimateView) {
      settitlePage(TitlePage.ESTIMATES)
    }
    else {
      settitlePage(TitlePage.DEMANDS)
    }
  }, [isEstimateView]);

  function onChangePage(event: React.ChangeEvent<unknown>, page: number) {
    setcurrentPage(page);

    if (filter === 'createdAt') {
      if (startDate && endDate) {
        dispatch(getFilteredPaginedEstimates({
          type: getTypeForWS(),
          property: filter,
          value: getValueForWS(),
          start_date: formatDateYYYY_MM_DD(startDate.toDate()),
          end_date: formatDateYYYY_MM_DD(endDate.toDate()),
          numPage: page,
          nbResultPerPage: rowsPerPage
        }));
      }
    }
    else if (filter === 'estimateNumber') {
      if (searchValue !== '') {
        dispatch(getFilteredPaginedEstimates({
          type: getTypeForWS(),
          property: filter,
          value: getValueForWS(),
          start_date: null,
          end_date: null,
          numPage: page,
          nbResultPerPage: rowsPerPage
        }));
      }
    }
    else if (filter === 'status') {
      if (status) {
        dispatch(getFilteredPaginedEstimates({
          type: getTypeForWS(),
          property: filter,
          value: getValueForWS(),
          start_date: null,
          end_date: null,
          numPage: page,
          nbResultPerPage: rowsPerPage
        }));
      }
    }
    else if (filter === 'empty') {
      dispatch(getFilteredPaginedEstimates({
        type: getTypeForWS(),
        numPage: page,
        property: filter,
        value: null,
        start_date: null,
        end_date: null,
        nbResultPerPage: rowsPerPage
      }))
    }
  }

  function handleToggleView(
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null,
  ) {

    if (newValue === EnumEstimateStatus.ESTIMATE) {
      setisEstimateView(true);
      //4 devis
      dispatch(getFilteredPaginedEstimates({
        type: 4,
        numPage: 1,
        property: 'empty',
        value: null,
        start_date: null,
        end_date: null,
        nbResultPerPage: rowsPerPage
      }))
    }
    else {
      setisEstimateView(false);
      //1 demande
      dispatch(getFilteredPaginedEstimates({
        type: 1,
        numPage: 1,
        property: 'empty',
        value: null,
        start_date: null,
        end_date: null,
        nbResultPerPage: rowsPerPage
      }))
    }
    setfilter('empty');
    setcurrentPage(1);
    setstartDate(null);
    setendDate(null);
    setsearchValue('');
    setstatus(null);
  }

  useEffect(() => {
    dispatch(getFilteredPaginedEstimates({
      type: 1,
      numPage: 1,
      property: filter,
      value: getValueForWS(),
      start_date: startDate ? formatDateYYYY_MM_DD(startDate.toDate()) : null,
      end_date: endDate ? formatDateYYYY_MM_DD(endDate.toDate()) : null,
      nbResultPerPage: rowsPerPage
    }))
    dispatch(getAllEstimateStatus());
  }, []);

  useEffect(() => {
    if (estimatesSelector.data.responseMessage?.status === 200) {
      setsnackbarMessage(estimatesSelector.data.responseMessage);
      setopenSnackbar(true);
      dispatch(estimatesSetResponseMessage(undefined));
    }
  }, [estimatesSelector]);

  function handleCloseSnackbar() {
    setopenSnackbar(false);
  }

  const handleChangeFilter = (event: SelectChangeEvent) => {
    setfilter(event.target.value as "status" | "createdAt" | "estimateNumber" | "empty");
  };

  const handleChangeStatus = (event: SelectChangeEvent) => {
    setstatus(Number(event.target.value));
  };

  function getValueForWS(): string | number | null | undefined {
    if (filter === 'estimateNumber') {
      return searchValue;
    }
    else if (filter === 'createdAt') {
      return null;
    }
    else if (filter === 'status') {
      return status;
    }
    return null;
  }

  function resetFilter() {
    setisEstimateView(false);
    setfilter('empty');
    setstatus(null);
    setsearchValue('');
    setstartDate(null);
    setendDate(null);
    dispatch(getFilteredPaginedEstimates({
      type: 1,
      numPage: 1,
      property: filter,
      value: null,
      start_date: null,
      end_date: null,
      nbResultPerPage: rowsPerPage
    }))
    setcurrentPage(1);
  }

  function getTypeForWS(): 1 | 4 {
    // 1 = demande, 4 = devis
    return isEstimateView ? 4 : 1;
  }

  /**
   * check si les filtres sont renseignés pour pouvoir lancer une recherche
   */
  function startSearch(): boolean {
    if (filter === 'status') {
      if (status !== undefined) return true
    }
    else if (filter === 'estimateNumber') {
      if (searchValue !== "") return true
    }
    else if (filter === 'createdAt') {
      if (startDate !== null && endDate !== null) return true
    }
    return false;
  }

  function onClickSearch() {
    if (startSearch()) {
      setcurrentPage(1)
      dispatch(getFilteredPaginedEstimates({
        type: getTypeForWS(),
        numPage: 1,
        property: filter,
        value: getValueForWS(),
        start_date: (filter === 'createdAt') && startDate ? formatDateYYYY_MM_DD(startDate.toDate()) : null,
        end_date: (filter === 'createdAt') && endDate ? formatDateYYYY_MM_DD(endDate.toDate()) : null,
        nbResultPerPage: rowsPerPage
      }))
    }
  }

  return (
    <Box className="page-container"
      sx={{
        backgroundImage: `url(${imageback})`,
        backgroundSize: 'cover',

      }}
    >
      <Container className="container">

        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: { lg: 3, xl: 6 }
        }}>
          <Box>
            <Typography className="title-page" variant='h4' component={'h1'}>{titlePage}</Typography>

            <ToggleButtonGroup
              value={isEstimateView}
              exclusive
              onChange={handleToggleView}
              aria-label="set way to view data"
              sx={{
                marginTop: { lg: 2.5, xl: 5 }
              }}
            >
              <ToggleButton value={EnumEstimateStatus.DEMANDS} aria-label="list" className={!isEstimateView ? 'white-button-selected' : 'white-button'}
                sx={{
                  backgroundColor: isEstimateView ? '#30353f' : 'white',
                  color: isEstimateView ? 'white' : '#30353f',
                  width: 160,
                  fontSize: '1em',
                  fontWeight: 'bold'
                }}
              >
                {EnumEstimateStatus.DEMANDS}
              </ToggleButton>
              <ToggleButton value={EnumEstimateStatus.ESTIMATE} aria-label="card" className={isEstimateView ? 'white-button-selected' : 'white-button'}
                sx={{
                  backgroundColor: !isEstimateView ? '#30353f' : 'white',
                  color: !isEstimateView ? 'white' : '#30353f',
                  width: 160
                }}
              >
                {EnumEstimateStatus.ESTIMATE}
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          {
            roleUserConnected && (roleUserConnected === Role.Prospect || roleUserConnected === Role.Customer || roleUserConnected === Role.SuperCustomer) &&
            <Button
              variant="text"
              className="btn-nouvelle-demande"
              sx={{
                backgroundColor: 'white',
                marginY: 'auto',
                textTransform: 'none',
              }}
              onClick={() => navigate(RouteApp.ESTIMATES + RouteApp.NEW_REQUEST)}
            >
              <PlusIcon className="icon-plus" />Nouvelle Demande
            </Button>
          }
        </Box>

        <Paper
          component="form"
          sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 540, marginBottom: '15px' }}
        >
          <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
            <Select
              labelId="select-filter-label"
              id="select-filter"
              value={filter}
              onChange={handleChangeFilter}
              label="Filtre"
            >
              <MenuItem value={'empty'}><em>Saisir filtre</em></MenuItem>
              <MenuItem value={'createdAt'}>Date commande</MenuItem>
              <MenuItem value={'estimateNumber'}>N°Commande</MenuItem>
              <MenuItem sx={{ display: isEstimateView ? 'none' : '' }} value={'status'}>Statut</MenuItem>
            </Select>
          </FormControl>
          {
            (filter === 'estimateNumber' || filter === 'empty') &&
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Rechercher"
              inputProps={{ 'aria-label': 'rechercher' }}
              value={searchValue}
              onChange={(e) => setsearchValue(e.target.value)}
            />
          }
          {
            filter === 'createdAt' &&
            <LocalizationProvider dateAdapter={AdapterDayjs} localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
              <DatePicker
                className="date-input"
                format="DD/MM/YYYY"
                value={startDate}
                onChange={(newValue: Dayjs | null) => {
                  if (newValue) {
                    setstartDate(newValue)
                  }
                }}
              />
              <DatePicker
                className="date-input"
                format="DD/MM/YYYY"
                value={endDate}
                onChange={(newValue: Dayjs | null) => {
                  if (newValue) {
                    setendDate(newValue)
                  }
                }}
              />
            </LocalizationProvider>
          }
          {
            filter === 'status' && !isEstimateView &&
            <FormControl variant="standard" sx={{ ml: 1, flex: 1 }}>
              <Select
                labelId="select-status-label"
                id="select-status"
                value={status?.toString()}
                onChange={handleChangeStatus}
                label="Statuts"
              >
                {
                  estimatesSelector.data.listEstimateStatus.map((estimateStatus: EstimateStatus, indice: number) => {
                    return <MenuItem key={indice} value={estimateStatus.id}>{estimateStatus.name}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
          }
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => {
            onClickSearch()
          }}>
            <SearchIcon />
          </IconButton>
          <IconButton type="button" sx={{ p: '10px' }} aria-label="remove" onClick={resetFilter}>
            <CloseIcon />
          </IconButton>
        </Paper>


        <div>
          {
            estimatesSelector.data.responsePaginedEstimates.estimates.map((estimate: Estimate, indice: number) => {
              return isEstimateView ?
                (
                  <ItemEstimateComponent key={indice} estimate={estimate}></ItemEstimateComponent>
                )
                :
                (
                  <ItemDemandComponent
                    demand={estimate} 
                    key={indice}
                    isEstimateView={isEstimateView} 
                    titlePage={titlePage}
                    currentPage={currentPage}
                    searchValue={searchValue}
                    status={status}
                    filter={filter}
                    startDate={startDate}
                    endDate={endDate}
                  ></ItemDemandComponent>
                );
            })
          }
        </div>




        <Box>
          <PaginationComponent
            currentPage={currentPage}
            nbTotalPage={estimatesSelector.data.responsePaginedEstimates.max_page}
            onChangePage={onChangePage}
          />
        </Box>

        <CustomSnackbar
          open={snackbarMessage !== undefined && openSnackbar}
          handleCloseSnackbar={handleCloseSnackbar}
          message={snackbarMessage?.message ? snackbarMessage.message : ''}
          severity={snackbarMessage?.status === 200 ? "success" : "error"}
          position={{ vertical: 'bottom', horizontal: 'center' }}
        />

      </Container>
    </Box>

  );
}

export default EstimatesPage;