import { AccordionSummary, Box, Grid, SxProps, Theme, Typography } from "@mui/material";
import React, { FunctionComponent } from "react";
import { OrderLine } from "../model/OrderDetail";
import './OrderProductsDetailsComponent.scss';

const isResoFullHD: boolean = window.innerWidth > 1280;

interface OrderProductsDetailsComponentProps {
  orderLine: OrderLine[];
}

const OrderProductsDetailsComponent: FunctionComponent<OrderProductsDetailsComponentProps> = (props: OrderProductsDetailsComponentProps) => {

  // const [expanded, setExpanded] = React.useState<string | false>(false);

  // const handleChange =
  //   (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
  //     setExpanded(isExpanded ? panel : false);
  //   };

  const sxAccordionSummary: SxProps<Theme> = {
    height: isResoFullHD ? '91px' : '60px',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)',
      marginRight: 3,
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      marginRight: 3,
    },
  }

  const sxBoxQuantityRounded: SxProps<Theme> = {
    background: '#E75A5A 0% 0% no-repeat padding-box',
    opacity: 1,
    width: '37px',
    height: '37px',
    borderRadius: '30px',
    display: 'flex',
  }

  const sxGridQuantity: SxProps<Theme> = {
    display: 'flex',
    justifyContent: 'flex-end'
  }

  const sxTextAccordionSummary: SxProps<Theme> = {
    textAlign: 'center',
    fontSize: isResoFullHD ? '20px' : '15px',
    color: '#707070'
  }

  return (
    <Box sx={{ overflowY: 'auto', maxHeight: isResoFullHD ? '340px' : '242px' }} className='product-container'>
      <Grid container spacing={1} sx={{ marginBottom: 1 }} className='product-header'>
        {/* <Grid item xs={1}></Grid> */}
        <Grid item xs={5}>
          <Typography sx={{ textAlign: 'center', fontSize: isResoFullHD ? '20px' : '18px', fontWeight: 'bold' }}>Référence</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography sx={{ textAlign: 'center', fontSize: isResoFullHD ? '20px' : '18px', fontWeight: 'bold' }}>Désignation</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography sx={{ textAlign: 'end', fontSize: isResoFullHD ? '20px' : '18px', fontWeight: 'bold' }}>Quantité</Typography>
        </Grid>
      </Grid>

      {
        props.orderLine.map((orderLine: OrderLine, indice: number) => {
          return (
            <AccordionSummary
              key={indice}
              sx={sxAccordionSummary}
            >
              <Grid container spacing={1} sx={{ fontSize: '10px'}}>
                <Grid item xs={5}>
                  <Typography sx={sxTextAccordionSummary}>{orderLine.reference}</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography sx={sxTextAccordionSummary}>{orderLine.designation}</Typography>
                </Grid>
                <Grid item xs={2} sx={sxGridQuantity}>
                  <Box sx={sxBoxQuantityRounded}>
                    <Typography sx={{ color: 'white', margin: 'auto' }}>{orderLine.quantity}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </AccordionSummary>
            );
        })
      }
    </Box >
  );
}

export default OrderProductsDetailsComponent;