import { Box, Grid } from "@mui/material";
import React, { FunctionComponent } from "react";
import { Order, OrderStatus } from "../model/Order";
import CardOrder from "./CardOrder";
import ListItemOrder from "./ListItemOrder";
import { Dayjs } from "dayjs";

interface OrdersByStateProps {
  isInListDisplayMode: boolean;
  state: OrderStatus.CURRENT | OrderStatus.PAST;
  orders: Order[];
  currentPage: number;
  isDisplayCurrentOrder: boolean;
  searchValue: string;
  status: number | null;
  filter: 'number' | 'orderDate' | 'deliveryDate' | 'company' | 'status' | 'applicant' | 'empty';
  startDate: Dayjs | null;
  endDate: Dayjs | null;
}

const OrdersByState: FunctionComponent<OrdersByStateProps> = (props: OrdersByStateProps) => {

  return (
    <Box sx={{ marginBottom: 3 }}>
      {
        props.isInListDisplayMode ?
          <Box>
            {
              props.orders.map((order: Order, indice: number) => {
                return <ListItemOrder key={indice} order={order} currentPage={props.currentPage} isListDisplay={props.isInListDisplayMode} isDisplayCurrentOrder={props.isDisplayCurrentOrder} searchValue={props.searchValue} status={props.status} filter={props.filter} startDate={props.startDate} endDate={props.endDate} />
              })
            }
          </Box>
          :
          <Grid container spacing={4}>
            {
              props.orders.map((order: Order, indice: number) => {
                return (
                  <Grid key={indice} item xs={3}>
                    <CardOrder order={order} currentPage={props.currentPage} isListDisplay={props.isInListDisplayMode} isDisplayCurrentOrder={props.isDisplayCurrentOrder} searchValue={props.searchValue} status={props.status} filter={props.filter} startDate={props.startDate} endDate={props.endDate} />
                  </Grid>
                )
              })
            }
          </Grid>

      }

    </Box>
  );
}

export default OrdersByState;