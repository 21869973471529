import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, Button, Chip, Container, Paper, Stack, TextField, Typography } from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomSnackbar from '../../common/component/CustomSnackbar';
import { ResponseMessage } from '../../common/model/ResponseMessage';
import { RouteApp } from "../../common/model/RouteApp";
import { Role } from "../../common/model/User";
import { useAppDispatch } from "../../common/redux/reduxHooks";
import { RootState } from "../../common/redux/store";
import { ReactComponent as UploadIcon } from '../../images/Icon-upload.svg';
import imageback from '../../images/img-fond-fonce.svg';
import { Estimate } from "../model/Estimate";
import { assignRequest, getDemandById, updateClarify, updateFiles } from "../redux/estimatesActions";
import { estimatesSetResponseMessage } from '../redux/estimatesSlice';
import './PriceRequestDetail.scss';
import QuestionAnswerComponent from "./QuestionAnswerComponent";

const isResoFullHD: boolean = window.innerWidth > 1280;

interface PriceRequestDetailProps {

}

const PriceRequestDetailPage: FunctionComponent<PriceRequestDetailProps> = () => {

  const navigate = useNavigate();

  const location = useLocation();

  const roleUserConnected: Role | undefined = useSelector((state: RootState) => state.userConnected.user?.roles ? state.userConnected.user?.roles[0] : undefined);

  const inputFileRef = React.useRef<HTMLInputElement>(null);

  const { idPriceRequest } = useParams();

  const dispatch = useAppDispatch();

  const estimateSelector: Estimate | undefined = useSelector((rootState: RootState) => rootState.estimates.data.estimate);
  const estimatesSelector = useSelector((rootState: RootState) => rootState.estimates);

  const [snackbarMessage, setsnackbarMessage] = useState<ResponseMessage | undefined>(undefined);
  const [openSnackbar, setopenSnackbar] = useState<boolean>(false);

  const [newMessage, setnewMessage] = useState<string>('');
  const [files, setfiles] = useState<File[]>([]);

  const [isClarifyPair, setisClarifyPair] = useState<boolean>(true);

  useEffect(() => {
    if (idPriceRequest) {
      dispatch(getDemandById(Number.parseInt(idPriceRequest)));
    }
  }, [idPriceRequest]);

  useEffect(() => {
    setisClarifyPair(estimateSelector !== undefined && estimateSelector.clarify !== undefined && estimateSelector.clarify.length % 2 === 0);
  }, [estimateSelector]);

  useEffect(() => {
    if (estimatesSelector.data.responseMessage?.status === 200 &&
      estimatesSelector.data.responseMessage?.message === 'Vous avez été assigné à la demande') {
      setsnackbarMessage(estimatesSelector.data.responseMessage);
      setopenSnackbar(true);
      dispatch(estimatesSetResponseMessage(undefined));
      estimatesSetResponseMessage(undefined);
    }
    else if (estimatesSelector.data.responseMessage?.status === 200) {
      navigate(RouteApp.ESTIMATES, { state: {
        currentPage: location.state['currentPage'],
        isEstimateView: location.state['isEstimateView'],
        searchValue: location.state['searchValue'],
        status: location.state['status'],
        filter: location.state['filter'],
        startDate: location.state['startDate'],
        endDate: location.state['endDate'],
        titlePage: location.state['titlePage']
  }});
    }
  }, [estimatesSelector]);

  const onClickInputFile = () => {
    if (inputFileRef.current && newMessage.length === 0)
      inputFileRef.current.click();
  };

  const handleChangeInputFile = function (e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    const filesToAdd: File[] = [];
    if (e.target.files && e.target.files[0]) {
      for (let index = 0; index < e.target.files.length; index++) {
        filesToAdd.push(e.target.files[index]);
      }
      setfiles(filesToAdd);
      setnewMessage('');
    }
  };

  function onClickDelete(fileName: string) {
    setfiles(files.filter((f: File) => f.name !== fileName));
  }

  function onClickSend() {
    if (idPriceRequest) {
      if (newMessage.length > 0) {
        dispatch(updateClarify({
          id: Number.parseInt(idPriceRequest),
          clarify: newMessage
        }));
      }
      else if (files.length > 0) {
        dispatch(updateFiles({
          id: Number.parseInt(idPriceRequest),
          files: files
        }))
      }
    }

  }

  function generateQuestionsAnswers() {
    let jsx: JSX.Element[] = [];
    if (estimateSelector?.clarify) {
      const clarify = estimateSelector?.clarify;

      for (let i = 0; i < clarify.length; i += 2) {
        if (isClarifyPair && clarify.length > 0) {
          jsx.push(<QuestionAnswerComponent
            clarification={clarify[i].message}
            response={clarify[i + 1] ? clarify[i + 1].message : ''}
            isDisabledResponse={true}
            setNewMessage={setnewMessage}
          />);
        }
        if (!isClarifyPair && (clarify.length - 1 === i)) {
          jsx.push(<QuestionAnswerComponent
            clarification={clarify[i].message}
            response={newMessage}
            isDisabledResponse={false}
            setNewMessage={setnewMessage}
          />);
        }
        else if (!isClarifyPair) {
          jsx.push(<QuestionAnswerComponent
            clarification={clarify[i].message}
            response={clarify[i + 1].message}
            isDisabledResponse={true}
            setNewMessage={setnewMessage}
          />);
        }
      }
    }
    return jsx;
  }

  function handleCloseSnackbar() {
    setopenSnackbar(false);
  }

  return (
    <Box
      className="page-container price-request-detail-page-container"
      sx={{
        backgroundImage: `url(${imageback})`,
      }}
    >
      <Container className="container" sx={{ paddingBottom: isResoFullHD ? undefined : '1em' }}>

        <Box className="header">
          <Typography className='title-page' variant='h4' component="h1">
            Demande de prix: {estimateSelector?.reference}
          </Typography>
          <Button onClick={() => navigate(RouteApp.ESTIMATES, { state: {
              currentPage: location.state['currentPage'],
              isEstimateView: location.state['isEstimateView'],
              searchValue: location.state['searchValue'],
              status: location.state['status'],
              filter: location.state['filter'],
              startDate: location.state['startDate'],
              endDate: location.state['endDate'],
              titlePage: location.state['titlePage']
            }})} variant="contained" className="back-button" startIcon={<KeyboardBackspaceIcon />}>
            Retour
          </Button>
        </Box>

        <Paper className="price-request-detail-information-container">
          <Box
            component="img"
            src={estimateSelector?.url_files ? estimateSelector?.url_files[0] : undefined}
            className="price-request-detail-information-img"
          />

          <Box className="price-request-detail-information-data-container">
            <Typography variant="h6" component="h2" className="price-request-detail-information-title">
              Informations complémentaires :
            </Typography>
            <Typography variant="body2" component="p" className="price-request-detail-information-data-complementary-information">
              {estimateSelector?.additional_information}
            </Typography>
            <Box display="flex">
              <Typography variant="h6" component="h2" className="price-request-detail-information-title">
                Produits :
              </Typography>
              <Typography variant="h6" component="h2" className="price-request-detail-information-data">
                {estimateSelector?.product}
              </Typography>

              <Typography variant="h6" component="h2" className="price-request-detail-information-title">
                Quantités :
              </Typography>
              <Typography variant="h6" component="h2" className="price-request-detail-information-data">
                {estimateSelector?.quantity}
              </Typography>
            </Box>
          </Box>

        </Paper>



        <div className="price-request-detail-question-response-container">
          {
            generateQuestionsAnswers().map((comp: JSX.Element, indice: number) => {
              return comp;
            })
          }

          {
            files.length > 0 &&
            <Box sx={{
              backgroundColor: 'white',
              padding: '10px',
              borderRadius: '20px',
              marginY: '10px'
            }}>
              <Stack direction="row" spacing={1}>
                {
                  files.map((f: File, indice: number) => {
                    return <Chip label={f.name} variant="outlined" onDelete={() => onClickDelete(f.name)} key={indice} />
                  })
                }
              </Stack>
            </Box>
          }

          {
            isClarifyPair && (roleUserConnected === Role.Admin || roleUserConnected === Role.Commercial) &&
            <Paper id='add-clarify-container' className="price-request-detail-clarification-request-container">
              <Typography variant="h6" component="h2" className="price-request-detail-information-title">
                Demande de clarification
              </Typography>
              <Box display={"flex"}>
                <TextField
                  id="clarification-request"
                  className="price-request-detail-clarification-request-textarea"
                  multiline
                  sx={{
                    flex: 1,
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: 'black',
                      },
                    },
                  }}
                  maxRows={4}
                  value={newMessage}
                  onChange={(e) => setnewMessage(e.target.value)}
                  disabled={files.length > 0}
                />

                <Typography variant="h6" component="h2" id="ou" className="price-request-detail-information-title">
                  Ou
                </Typography>

                <Box className={newMessage.length > 0 ? "price-request-detail-upload-container-disabled" : "price-request-detail-upload-container"} onClick={() => onClickInputFile()}>
                  <input ref={inputFileRef} type="file" id="input-file" onChange={(e) => handleChangeInputFile(e)} accept={".pdf"} />
                  <UploadIcon />
                  <Typography variant="h6" component="h2" className="price-request-detail-information-title">
                    Uploader un devis
                  </Typography>
                </Box>
              </Box>
            </Paper>
          }


        </div>
        <Box display={'flex'} justifyContent={'center'}>
          {
            estimateSelector?.status?.id === 1 && roleUserConnected === Role.Commercial &&
            (estimateSelector?.commercial === undefined || estimateSelector?.commercial === null) &&
            <Button variant="contained" className="send-button" onClick={() => dispatch(assignRequest(estimateSelector.id!))}>
              prendre en charge
            </Button>
          }

          {
            (newMessage.length > 0 || files.length > 0) &&
            <Button variant="contained" className="send-button" onClick={() => onClickSend()}>
              Envoyer
            </Button>
          }
        </Box>

        <CustomSnackbar
          open={snackbarMessage !== undefined && openSnackbar}
          handleCloseSnackbar={handleCloseSnackbar}
          message={snackbarMessage?.message ? snackbarMessage.message : ''}
          severity={snackbarMessage?.status === 200 ? "success" : "error"}
          position={{ vertical: 'bottom', horizontal: 'center' }}
        />

      </Container>
    </Box>
  );
}

export default PriceRequestDetailPage;